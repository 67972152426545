import React from "react";
import { Redirect, Route } from "react-router-dom";
import ClientAccessToken from "./pages/client-access-token/";
import FeatureFlag from "./pages/feature-flag/";
import FeatureFlags from "./pages/feature-flags/";
import Landing from "./pages/landing/";
import LegalPage from "./pages/legal/";
import LoginPage from "./pages/login/";
import PaymentSuccess from "./pages/payment-success/";
import Payments from "./pages/payments/";
import Product from "./pages/product/";
import Products from "./pages/products/";
import Project from "./pages/project/";
import Projects from "./pages/projects/";
import Script from "./pages/script/";
import Scripts from "./pages/scripts/";
import Service from "./pages/service/";
import Services from "./pages/services/";
import Settings from "./pages/settings/";
import Template from "./pages/template/";
import Templates from "./pages/templates/";
import Docs from "./pages/docs/";
import User from "./pages/user/";
import Users from "./pages/users/";
import "./style.css";

export const unprotectedPaths = [
  ["", Landing],
  ["login", LoginPage],
  ["landing", Landing],
  ["client_access_tokens/:id", ClientAccessToken, "Client Access Token"],
  ["docs", Docs, "Docs"],
  ["services", Services, "Services"],
  ["services/:id", Service, "Service"],
  ["payments", Payments, "Payments"],
  ["payment-success", PaymentSuccess, "Payment Success"],
  ["products", Products, "Products"],
  ["products/:id", Product, "Product"],
  ["templates", Templates, "Templates"],
  ["templates/:id", Template, "Template"],
  ["legal", LegalPage, "Legal"],
  ["settings", Settings, "Settings"],
  ["scripts", Scripts, "Scripts"],
  ["scripts/:id", Script, "Script"],
];

export const adminPaths = [
  ["admin/users", Users, "Users"],
  ["admin/users/:id", User, "User"],
  ["admin/feature-flags", FeatureFlags, "Feature Flags"],
  ["admin/feature-flags/:id", FeatureFlag, "Feature Flag"],
  ["admin/projects", Projects, "Projects"],
  ["admin/projects/:id", Project, "Project"],
];

export const RoutePath = route => {
  return (
    <Route
      key={"route-key-" + route[0]}
      exact
      path={"/" + route[0]}
      component={route[1]}
    />
  );
};

export const ProtectedRoutePath = (route, loading, user) => {
  if (loading) {
    return (
      <Route key={"route-key-" + route[0]} exact path={"/" + route[0]}>
        <div>Loading...</div>
      </Route>
    );
  } else if (user) {
    return RoutePath(route);
  }
  return <Redirect key={"route-key-" + route[0]} to="/login" />;
};
