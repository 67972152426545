import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import TemplateIcon from "@mui/icons-material/DataObject";
import FlagIcon from "@mui/icons-material/Flag";
import GroupIcon from "@mui/icons-material/Group";
import MenuIcon from "@mui/icons-material/Menu";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import SettingsIcon from "@mui/icons-material/Settings";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import { auth, db, signInWithGooglePopup } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import Ff from "../ff/";

import "./style.css";

const drawerWidth = 180;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: -12,
  marginTop: 16,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(
  MuiDrawer,
  {}
)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const earlyAccess = [
  [<TemplateIcon />, "scripts", "Scripts"],
  [<CollectionsBookmarkIcon />, "docs", "Docs"],
  [<CreditCardIcon />, "payments", "Payments"],
  [<SettingsIcon />, "settings", "Settings"],
];

const admin = [
  [<GroupIcon />, "admin/users", "Users"],
  [<AutoAwesomeMosaicIcon />, "admin/projects", "Projects"],
  [<FlagIcon />, "admin/feature-flags", "Flags"],
];

// useMemo to prevent re-rendering of the title if the project name doesn't change
function TitleyDooComponent({ title = "" }) {
  return (
    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} key="jfoe">
      {title && <span>{title}</span>}
    </Typography>
  );
}
const TitleyDoo = React.memo(TitleyDooComponent);

const MinorFrame = props => {
  const [user] = useAuthState(auth);
  const { activeProjectId = "" } = useEphemera();
  const [project, setProject] = useState({});
  const [open, setOpen] = useState(false);

  // get the project
  useEffect(() => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists()) return;
      const data = snap.data();
      setProject(data);
    });
    return unsubscribe;
  }, [activeProjectId]);

  async function signIn() {
    console.log("Signing in...");
    try {
      const creds = await signInWithGooglePopup();
      return creds;
    } catch (e) {
      console.error("failed to sign in");
      return null;
    }
  }

  const toggleDrawer = () => setOpen(!open);

  const NavBar = () => {
    return (
      <AppBar
        position="fixed"
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className="TopNav">
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Moxy: {project?.displayName ? `${project.displayName}` : null}
          </Typography>
          {user ? null : (
            <Button color="inherit" onClick={() => signIn()}>
              Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  const MobileNavBar = () => {
    return (
      <AppBar
        position="fixed"
        sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className="TopNav">
          {props.upperLeftNav && (
            <Tooltip title={open ? "Close" : "Open"} placement="right">
              <IconButton onClick={toggleDrawer} style={{ marginLeft: -12 }}>
                <MenuIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          )}
          <TitleyDoo title={"Moxy: " + project?.displayName} />
          {user ? null : (
            <Button color="inherit" onClick={() => signIn()}>
              Sign In
            </Button>
          )}
        </Toolbar>
      </AppBar>
    );
  };

  const MobileMenuItem = ([icon, link, text]) => (
    <Link to={`/${link}`} key={text} style={{ textDecoration: "none" }}>
      <Tooltip title={text} placement="right" disableHoverListener={open}>
        <ListItem
          button
          disablePadding
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        </ListItem>
      </Tooltip>
    </Link>
  );

  const MenuItem = ([icon, link, text]) => (
    <Link to={`/${link}`} key={text} style={{ textDecoration: "none" }}>
      <Tooltip title={text} placement="right" disableHoverListener={open}>
        <ListItem
          button
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        </ListItem>
      </Tooltip>
    </Link>
  );

  const renderMobileUpperLeftNav = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MobileNavBar />
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <div style={{ height: 64 }} />
          <Box
            role="presentation"
            sx={{ overflow: "auto", overflowX: "hidden", width: 250 }}
            onClick={() => setOpen(false)}
          >
            <List>{earlyAccess.map(MobileMenuItem)}</List>
            <Divider />
            <List>
              <Ff flag="admin">{admin.map(MobileMenuItem)}</Ff>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    );
  };

  const renderMobileLowerRightNav = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <MobileNavBar />
        <Drawer
          variant="temporary"
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
        >
          <div style={{ height: 64 }} />
          <Box
            role="presentation"
            sx={{
              overflow: "auto",
              overflowX: "hidden",
              width: 250,
              // make the contents of the drawer stick to the bottom
              display: "flex",
              marginTop: "auto",
              flexDirection: "column",
            }}
            onClick={() => setOpen(false)}
          >
            <div
              style={{
                marginTop: "auto",
              }}
            >
              <List>{earlyAccess.map(MobileMenuItem)}</List>
              <Divider />
              <List>
                <Ff flag="admin">{admin.map(MobileMenuItem)}</Ff>
              </List>
              <div style={{ height: "64px" }} />
            </div>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {props.children}
        </Box>
        <div
          style={{
            position: "fixed",
            bottom: "24px",
            right: "24px",
            background: "#555",
            border: "1px solid #fff",
          }}
        >
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
        </div>
      </Box>
    );
  };

  const renderMobileVersion = () => {
    if (props.upperLeftNav) return renderMobileUpperLeftNav();
    return renderMobileLowerRightNav();
  };

  const renderDesktopVersion = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <NavBar />
        <Drawer variant="permanent" open={open}>
          <div style={{ height: 76 }} />
          <DrawerHeader>
            <Tooltip title={open ? "Close" : "Open"} placement="right">
              <IconButton onClick={toggleDrawer}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </Tooltip>
          </DrawerHeader>
          <Box sx={{ overflow: "auto", overflowX: "hidden" }}>
            <List>{earlyAccess.map(MenuItem)}</List>
            <Divider />
            <List>
              <Ff flag="admin">{admin.map(MenuItem)}</Ff>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    );
  };

  if (window.innerWidth < 600) return renderMobileVersion();
  return renderDesktopVersion();
};

export default MinorFrame;
