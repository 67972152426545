import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db, auth } from "../../db";

const COLLECTION = "feature-flags";

function Ff(props) {
  // renders children only if the feature flag is enabled
  const [enabled, setEnabled] = useState(false);
  const [user, loading = true] = useAuthState(auth);
  const { flag = "" } = props;
  useEffect(() => {
    if (!flag) return;
    if (!user) return;
    const { uid } = user;
    const docRef = doc(db, COLLECTION, flag, "users", uid);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (snap.exists()) setEnabled(true);
    });
    return unsubscribe;
  }, [flag, loading, user]);
  if (!enabled) return null;
  return <>{props.children}</>;
}

export default Ff;
