import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { db } from "../../db";
import { ClientAccessToken } from "../../models";
import useEphemera from "../../hooks/useEphemera";

function Spacer() {
  return <div style={{ height: 20 }} />;
}

export function ClientAccessTokenDashboard() {
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();
  const [records, setRecords] = useState([]);
  const [queryLimit, setQueryLimit] = useState(10);
  const COLLECTION = "client_access_tokens";

  useEffect(() => {
    if (!activeProjectId) return;
    const collectionRef = collection(
      db,
      "projects",
      activeProjectId,
      COLLECTION
    );
    const q = query(
      collectionRef,
      orderBy("lastUpdated", "desc"),
      limit(queryLimit)
    );
    const unsub = onSnapshot(q, querySnapshot => {
      const records = [];
      querySnapshot.forEach(doc => {
        const data = doc.data();
        records.push(data);
      });
      setRecords(records);
    });
    return () => unsub();
  }, [queryLimit, activeProjectId]);

  const loadMore = () => setQueryLimit(queryLimit + 10);
  const hasMore = records.length >= queryLimit;

  const createClientAccessToken = () => {
    if (!activeProjectId) return;
    const rec = ClientAccessToken();
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, rec.id);
    setDoc(docRef, rec);
    history.push("/" + COLLECTION + "/" + rec.id);
  };

  return (
    <div>
      <h2>Client Access Tokens</h2>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>token</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => {
              const { id, displayName = "" } = record;
              const path = "/" + COLLECTION + "/" + id;
              return (
                <tr
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {displayName}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {id}
                  </TableCell>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore && (
        <Button onClick={loadMore} style={{ float: "right" }}>
          Load More
        </Button>
      )}
      <Spacer />
      <Button onClick={createClientAccessToken} variant="contained">
        Add New Client Access Token
      </Button>
    </div>
  );
}
