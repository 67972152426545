import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  arrayUnion,
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import MinorFrame from "../../components/minor-frame/";
import TextField from "../../components/text-field/";
import { db, log, useLogPageView } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { ApiTest, Header } from "../../models";
import "./style.css";

const COLLECTION = "services";

const Space = () => <div style={{ height: "12px" }} />;

function Data() {
  useLogPageView("Service");
  const { activeProjectId = "" } = useEphemera();
  const history = useHistory();
  const { id } = useParams();
  const [record, setRecord] = useState(null);
  const recordId = id;

  useEffect(() => {
    if (!recordId) return;
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, recordId);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (!snap.exists) return;
      const data = snap.data();
      setRecord(data);
    });
    return unsubscribe;
  }, [recordId, activeProjectId]);

  const deleteRecord = async () => {
    if (!activeProjectId) return;
    log("deleteService");
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, recordId);
    await deleteDoc(docRef);
    history.push("/" + COLLECTION);
  };

  const updateRecord = update => {
    if (!activeProjectId) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, recordId);
    const lastUpdated = serverTimestamp();
    updateDoc(docRef, { ...update, lastUpdated }, { merge: true });
  };

  const onEditExampleData = data => {
    updateRecord({ exampleRequestJSON: data.updated_src });
  };

  const onDeleteExampleData = data => {
    updateRecord({ exampleRequestJSON: data.updated_src });
  };

  const onAddExampleData = data => {
    updateRecord({ exampleRequestJSON: data.updated_src });
  };

  const setDisplayName = displayName => updateRecord({ displayName });

  const setDescription = description => updateRecord({ description });

  const setURL = url => updateRecord({ url });

  const setBasicAuthUsername = username => {
    updateRecord({ basicAuth: { username } });
  };

  const setBasicAuthPassword = password => {
    updateRecord({ basicAuth: { password } });
  };

  const addHeader = () => updateRecord({ headers: arrayUnion(Header()) });

  const testAPI = async () => {
    if (!activeProjectId) return;
    log("testServiceAPI");
    // we're going to trigger the creation of a document on the backend
    // that will call the Service's API
    const apiTestRec = ApiTest();
    apiTestRec.serviceId = recordId;
    apiTestRec.serviceDisplayName = record.displayName;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      COLLECTION,
      recordId,
      "api_tests",
      apiTestRec.id
    );
    await setDoc(docRef, apiTestRec);
  };

  const duplicateService = async () => {
    if (!activeProjectId) return;
    log("duplicateService");
    const id = uuid();
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const rec = { ...record };
    rec.id = id;
    rec.displayName = rec.displayName + " (copy)";
    await setDoc(docRef, rec);
    history.push("/" + COLLECTION + "/" + id);
  };

  const renderGetServiceTest = () => {
    let exampleResponse = <p>{record?.exampleResponse || ""}</p>;
    try {
      exampleResponse = (
        <ReactJson
          src={JSON.parse(record?.exampleResponse) || {}}
          name={false}
          indentWidth={2}
          collapsed={2}
          collapseStringsAfterLength={24}
          displayDataTypes={false}
        ></ReactJson>
      );
    } catch (e) {
      exampleResponse = <p>{record?.exampleResponse || ""}</p>;
    }
    return (
      <div className="api-test-area">
        <Button
          onClick={testAPI}
          variant="contained"
          sx={{ background: "#555", float: "right", marginTop: 2 }}
        >
          Test API
        </Button>
        <h2>API Test Diagnostics</h2>
        <div className="test-card">
          <h3>Example Request</h3>
          <p className="faint">(this will be appended to the url)</p>
          <TextField
            fullWidth
            value={record.exampleRequest || ""}
            onChange={e => updateRecord({ exampleRequest: e.target.value })}
          />
        </div>
        <div className="test-card">
          <h3>Example Response</h3>
          {exampleResponse}
        </div>
      </div>
    );
  };

  const renderPostServiceTest = () => {
    return (
      <div className="api-test-area">
        <Button
          onClick={testAPI}
          variant="contained"
          sx={{ background: "#555", float: "right", marginTop: 2 }}
        >
          Test API
        </Button>
        <h2>API Test Diagnostics</h2>
        <div className="test-card">
          <h3>Example Request JSON</h3>
          <ReactJson
            src={record.exampleRequestJSON || {}}
            name={false}
            indentWidth={2}
            collapsed={2}
            collapseStringsAfterLength={12}
            displayDataTypes={false}
            onEdit={onEditExampleData}
            onDelete={onDeleteExampleData}
            onAdd={onAddExampleData}
          />
        </div>
        <div className="test-card">
          <h3>Example Response JSON</h3>
          <ReactJson
            src={record.exampleResponseJSON || {}}
            name={false}
            indentWidth={2}
            collapsed={2}
            collapseStringsAfterLength={12}
          />
        </div>
      </div>
    );
  };

  const renderServiceTest = () => {
    if (!record) return null;
    if (record?.httpMethod === "GET") return renderGetServiceTest();
    return renderPostServiceTest();
  };

  if (!record) return "Loading...";

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            type="text"
            label="Service Name"
            variant="standard"
            fullWidth
            value={record?.displayName}
            onChange={e => setDisplayName(e.target.value)}
            InputProps={{ style: { fontSize: "3em" } }}
          />
          <Space />
          <TextField
            type="text"
            label="Description"
            variant="standard"
            fullWidth
            value={record?.description || ""}
            onChange={e => setDescription(e.target.value)}
          />
          <Space />
          <Space />
          <TextField
            type="text"
            label="URL"
            variant="standard"
            fullWidth
            value={record.url || ""}
            onChange={e => setURL(e.target.value)}
          />
          <Space />
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="http-method"
              name="http-method"
              value={record.httpMethod || "POST"}
              onChange={e => updateRecord({ httpMethod: e.target.value })}
            >
              <FormControlLabel value="POST" control={<Radio />} label="POST" />
              <FormControlLabel value="GET" control={<Radio />} label="GET" />
            </RadioGroup>
          </FormControl>

          <Space />
          <TextField
            type="text"
            label="Basic Auth: Username"
            variant="standard"
            fullWidth
            value={record.basicAuth.username || ""}
            onChange={e => setBasicAuthUsername(e.target.value)}
          />
          <Space />
          <TextField
            type="text"
            label="Basic Auth: Password"
            variant="standard"
            fullWidth
            value={record.basicAuth.password || ""}
            onChange={e => setBasicAuthPassword(e.target.value)}
          />
          <Space />
          {record.headers.map((header, index) => {
            return (
              <div className="header-container" key={header.id}>
                <Grid container columnSpacing={{ xs: 4 }}>
                  <Grid item xs={3}>
                    <TextField
                      type="text"
                      label="Header: Key"
                      variant="standard"
                      fullWidth
                      value={header.key}
                      onChange={e => {
                        if (!activeProjectId) return;
                        const docRef = doc(
                          db,
                          "projects",
                          activeProjectId,
                          COLLECTION,
                          recordId
                        );
                        const lastUpdated = serverTimestamp();
                        const headers = record.headers;
                        headers[index].key = e.target.value;
                        updateDoc(docRef, { headers, lastUpdated });
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      type="text"
                      label="Header: Value"
                      variant="standard"
                      fullWidth
                      value={header.value}
                      onChange={e => {
                        if (!activeProjectId) return;
                        const docRef = doc(
                          db,
                          "projects",
                          activeProjectId,
                          COLLECTION,
                          recordId
                        );
                        const lastUpdated = serverTimestamp();
                        const headers = record.headers;
                        headers[index].value = e.target.value;
                        updateDoc(docRef, { headers, lastUpdated });
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Space />
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => {
                        if (!activeProjectId) return;
                        const docRef = doc(
                          db,
                          "projects",
                          activeProjectId,
                          COLLECTION,
                          recordId
                        );
                        const lastUpdated = serverTimestamp();
                        const headers = record.headers;
                        headers.splice(index, 1);
                        updateDoc(docRef, { headers, lastUpdated });
                      }}
                    >
                      Delete Header
                    </Button>
                  </Grid>
                </Grid>
              </div>
            );
          })}
          <Space />
          <Button onClick={addHeader}>Add Header</Button>
          <Space />
          <Space />
        </Grid>
        <Grid item xs={6}>
          {renderServiceTest()}
          <Button
            className="float-right"
            sx={{ background: "#333" }}
            onClick={duplicateService}
            variant="contained"
            color="primary"
          >
            Duplicate {record?.displayName || "Plan"}
          </Button>
        </Grid>
      </Grid>
      <Card variant="outlined" className="padded DeletionArea">
        <h3> Danger Zone</h3>
        <Button variant="contained" color="secondary" onClick={deleteRecord}>
          Delete {record.displayName}
        </Button>
      </Card>
    </div>
  );
}

const Page = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default Page;
