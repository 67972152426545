/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";
import MinorFrame from "../../components/minor-frame/";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import "./style.css";

const Space = () => <div style={{ height: "12px" }} />;

function Data() {
  const { activeProjectId } = useEphemera();
  const colPath = `projects/${activeProjectId}/client_access_tokens`;
  const [tokens = []] = useFirestorePagination(colPath, 1, "lastUpdated");
  const token = tokens[0]?.id;

  return (
    <div className="docs-page">
      <h2>Docs</h2>
      <pre>
        <code>
          {`
curl -X POST https://moxymachine.com/api/v1/scripts \\
-H "Content-Type: application/json" \\
-H "Authorization: Bearer ${token}" \\
-H "X-Project-ID: ${activeProjectId}" \\
-d '{\"code\": \"Math.random() * 1000 | 0\"}'
            `}
        </code>
      </pre>
      <p>
        This really couldn't be simpler: just send a POST to the right url with
        the right headers and {"{code: 'some JS'}"} and it'll eval that code and
        respond with whatever the last expression evaluates to.
      </p>
      <p>
        All your scripts are saved and can be accessed via
        <Link to="/scripts"> the scripts page</Link>.
      </p>
      <p>
        They all get run immediately after they're created (unless you say
        otherwise), and then again whenever you call them via the API or through
        the web interface.
      </p>
      <h2>FAQ</h2>
      <h4>Can I install NPM packages?</h4>
      <p>No.</p>
      <h4>Do I need to wrap my code in a main() function?</h4>
      <p>
        Not really. The API will just respond with the last expression evaluated
        as.
      </p>
      <h4>Can I use TypeScript</h4>
      <p>No.</p>
      <h4>Can I use fetch?</h4>
      <p>No.</p>
      <h4>How long does a script have to execute before it times out?</h4>
      <p>60 seconds, approximately.</p>
      <h4>Why are you doing this?</h4>
      <p>
        Because I wanted my LLM based agents to be able to execute the code that
        they were generating, but God only knows what the hell they're doing, so
        I'd rather have that shit running in a secure sandbox, preferrably on a
        different computer.
      </p>
      <h4>Where's my project id?</h4>
      <p>
        On the <Link to="/settings">settings page</Link>
      </p>
      <h4>Where's my client access token?</h4>
      <p>
        On the <Link to="/settings">settings page</Link>
      </p>
      <h4>Can I have multiple projects?</h4>
      <p>
        Yeah (on the <Link to="/settings">settings page</Link>), sure, but you
        only get free credits for the first project.
      </p>
      <h4>
        Can I have collaborators? Like other people with access to my projects?
      </h4>
      <p>
        Hypothetically yes, but that feature's kinda early in development. After
        your friend makes an account, add their email (on the settings page) and
        they should see access to the project pop up in their list of projects.
      </p>
    </div>
  );
}

const DocsPage = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default DocsPage;
