import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { signOut } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import { auth, signInWithGooglePopup } from "../../db";
import "./style.css";

function Double({ children }) {
  return (
    <Grid container className="double">
      <Grid item xs={12} md={6}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={6}>
        {children[1]}
      </Grid>
    </Grid>
  );
}

function RevDouble({ children }) {
  // if the size is small, and the elements stack, then make the second element
  // come first, like reverse the order
  return (
    <Grid container direction="row-reverse" className="double">
      <Grid item xs={12} md={6}>
        {children[1]}
      </Grid>
      <Grid item xs={12} md={6}>
        {children[0]}
      </Grid>
    </Grid>
  );
}

function Triple({ children }) {
  // a three column layout
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        {children[0]}
      </Grid>
      <Grid item xs={12} md={4}>
        {children[1]}
      </Grid>
      <Grid item xs={12} md={4}>
        {children[2]}
      </Grid>
    </Grid>
  );
}

function Hextuple({ children }) {
  return (
    <Grid container className="centered">
      <Grid item xs={6} md={2}>
        {children[0]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[1]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[2]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[3]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[4]}
      </Grid>
      <Grid item xs={6} md={2}>
        {children[5]}
      </Grid>
    </Grid>
  );
}

function Footer() {
  return <div className="landing-footer">support@moxymachine.com</div>;
}

const taglines = ["Moxy Machine is a Simple Sandbox as a Service."];

const tagline = taglines[Math.floor(Math.random() * taglines.length)];

export default function Landing() {
  const [user] = useAuthState(auth);
  const history = useHistory();

  async function logOut() {
    await signOut(auth);
    history.push("/");
  }

  async function signIn() {
    try {
      const creds = await signInWithGooglePopup();
      history.push("/scripts");
      return creds;
    } catch (e) {
      console.error("failed to sign in");
      return null;
    }
  }

  const linkContainerStyle = {
    color: "#fff",
    textDecoration: "none",
  };

  return (
    <div className="App">
      <div className="background-container"></div>
      <AppBar position="static" sx={{ backgroundColor: "#282c34" }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Moxy Machine
          </Typography>
          {user ? (
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <Link style={linkContainerStyle} to="/scripts">
                scripts
              </Link>{" "}
            </Typography>
          ) : null}
          {user ? (
            <Button color="inherit" onClick={() => logOut()}>
              SIGN OUT
            </Button>
          ) : (
            <Button color="inherit" onClick={() => signIn()}>
              SIGN IN
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <h1 className="landing-exclaimation">eval() as a service</h1>
      <div className="cta-container">
        <button
          className="lfg-button"
          onClick={() => {
            user ? history.push("/scripts") : signIn();
          }}
        >
          LFG!
        </button>
      </div>
      <div className="landing-content">
        <p className="dark centered big">{tagline}</p>
        <p className="landing-exposition">
          Moxy Machine is eval() as a service. Use the API to send a chunk of JS
          and we'll eval it in a (probably) safe sandbox and send you back the
          results.
        </p>
        <div className="centered">
          <h2>Get started now for free</h2>
          {user ? null : (
            <button
              className="sign-in-button fade-up-second"
              onClick={() => signIn()}
            >
              SIGN IN
            </button>
          )}
          {user ? (
            <>
              <span style={{ paddingLeft: 20 }}>
                <Link to="/chats">Scripts</Link>
              </span>
              <span style={{ paddingLeft: 20 }}>
                <Link to="/docs">API Documentation</Link>
              </span>
            </>
          ) : null}
        </div>
        <Footer />
      </div>
    </div>
  );
}
