/* eslint-disable */
import ReactJson from "react-json-view";
import { javascript } from "@codemirror/lang-javascript";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CodeMirror from "@uiw/react-codemirror";
import {
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Sigil from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import TextField from "../../components/text-field/";
import { db } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePaginationWithWhere } from "../../hooks/useFirestorePagination";
import { ScriptRun } from "../../models";
import "./style.css";
import { Grid } from "@mui/material";

const COLLECTION = "scripts";

const isObject = obj => typeof obj === "object" && obj !== null;

const StdOut = ({ scriptRun = {} }) => {
  let { result, error } = scriptRun;
  let res = null;
  if (isObject(result)) {
    res = (
      <ReactJson
        src={result || error || {}}
        name={false}
        indentWidth={2}
        collapsed={2}
        collapseStringsAfterLength={24}
        displayDataTypes={false}
      ></ReactJson>
    );
  } else {
    res = <span className="wrap-words">{result || error}</span>;
  }
  return res;
};

const Space = () => <div style={{ height: "12px" }} />;

const ScriptPage = () => {
  const { id } = useParams();
  const { activeProjectId } = useEphemera();
  const history = useHistory();
  const [record, setRecord] = useState(null);
  const colPath = `projects/${activeProjectId}/script_runs`;
  const [runs = [], loadMore, hasMore] = useFirestorePaginationWithWhere(
    colPath,
    3,
    "created",
    ["scriptId", "==", id]
  );

  useEffect(() => {
    if (!activeProjectId || !id) return;
    const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
    const unsubscribe = onSnapshot(docRef, snap => {
      if (snap.exists) {
        const record = snap.data();
        setRecord(record);
      }
    });
    return unsubscribe;
  }, [activeProjectId, id]);

  const updateRecord = useCallback(
    opts => {
      const docRef = doc(db, "projects", activeProjectId, COLLECTION, id);
      opts = JSON.parse(JSON.stringify(opts));
      return updateDoc(
        docRef,
        { ...opts, lastUpdated: serverTimestamp() },
        { merge: true }
      );
    },
    [id, activeProjectId]
  );

  const deleteRecord = async () => {
    await deleteDoc(
      doc(db, "projects", activeProjectId, COLLECTION, record.id)
    );
    history.push("/" + COLLECTION);
  };

  const run = async () => {
    // creates a new script_run document with the script id
    const scriptRun = ScriptRun(id);
    scriptRun.scriptDisplayName = record.displayName;
    const docRef = doc(
      db,
      "projects",
      activeProjectId,
      "script_runs",
      scriptRun.id
    );
    await setDoc(docRef, scriptRun);
  };

  if (!record) return <h1>Loading...</h1>;

  return (
    <MinorFrame>
      <div className="padded">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={2}>
              <Sigil seed={record.id} style={{ width: 128 }} gen={"xenophon"} />
              <div style={{ marginTop: 40, width: "100%" }}>
                <TextField
                  type="text"
                  label="Script Name"
                  variant="standard"
                  fullWidth
                  value={record.displayName}
                  onChange={e => updateRecord({ displayName: e.target.value })}
                />
              </div>
            </Stack>
            <Space />
            <CodeMirror
              value={record.code}
              theme="dark"
              extensions={[javascript({ jsx: true })]}
              onChange={(code, viewUpdate) => {
                updateRecord({ code });
              }}
            />
            <Button onClick={run}>Run</Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="script-output">
              {runs.map((scriptRun, i) => (
                <div
                  className="script-output-card animated-fade-in-from-right"
                  style={{ "--delay": i * 0.1 + "s" }}
                  key={"script-output-" + scriptRun?.id}
                >
                  <span className="faint datetime-container">
                    {new Date(scriptRun?.created?.seconds * 1000).toISOString()}
                  </span>
                  <p className="status-container">
                    Status: {scriptRun?.status || "UNKNOWN"}
                  </p>
                  {scriptRun?.status !== "RUNNING" && (
                    <div
                      className="animated-fade-in-from-bottom script-result"
                      key={"script-result-" + scriptRun?.id}
                      style={{ "--delay": i ? "0s" : i * 0.2 + "s" }}
                    >
                      <h3>
                        {!scriptRun?.result && scriptRun.error
                          ? "Error"
                          : "Result"}
                        :
                      </h3>
                      <StdOut scriptRun={scriptRun} />
                    </div>
                  )}
                  <p className="origin-container">
                    Origin: {scriptRun?.origin || "UNKNOWN"}
                  </p>
                </div>
              ))}
            </div>
            {hasMore && <Button onClick={loadMore}>Load More</Button>}
          </Grid>
        </Grid>
        <Card variant="outlined" className="padded DeletionArea">
          <h3> Danger Zone</h3>
          <Button variant="contained" color="secondary" onClick={deleteRecord}>
            Delete {record.displayName}
          </Button>
        </Card>
      </div>
    </MinorFrame>
  );
};

export default ScriptPage;
