/* eslint-disable */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { doc, setDoc } from "firebase/firestore";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import Sigil from "../../components/canvas/";
import MinorFrame from "../../components/minor-frame/";
import { db } from "../../db";
import useEphemera from "../../hooks/useEphemera";
import { useFirestorePagination } from "../../hooks/useFirestorePagination";
import { Script } from "../../models";
import "./style.css";

const COLLECTION = "scripts";

const Space = () => <div style={{ height: "12px" }} />;

function Data() {
  const { activeProjectId } = useEphemera();
  const colPath = `projects/${activeProjectId}/${COLLECTION}`;
  const [records = [], loadMore, hasMore] = useFirestorePagination(colPath);
  const history = useHistory();

  const createRecord = () => {
    const record = Script();
    const docRef = doc(db, "projects", activeProjectId, "scripts", record.id);
    setDoc(docRef, record);
    history.push(`/scripts/${record.id}`);
  };

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }} className="padded">
      <Space />
      <Button
        variant="contained"
        onClick={createRecord}
        style={{ float: "right" }}
      >
        Create new Script
      </Button>
      <h2>Scripts</h2>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: 0, width: 128 }}></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Last Updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((rec, index) => {
              const { id, displayName, lastUpdated } = rec;
              const path = "/scripts/" + id;
              return (
                <TableRow
                  key={id}
                  className="animated-fade-in-from-left"
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "--delay": index * 0.1 + "s",
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: 0, width: 128 }}
                  >
                    <div className="sigil-background-container">
                      <Sigil
                        seed={id}
                        style={{ width: 400 }}
                        gen={"xenophon"}
                      />
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={path}> {displayName} </Link>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {lastUpdated
                      ? new Date(lastUpdated.seconds * 1000).toLocaleString()
                      : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {hasMore ? <Button onClick={loadMore}>Load more</Button> : null}
    </Box>
  );
}

const ScriptsPage = () => {
  return (
    <MinorFrame>
      <Data />
    </MinorFrame>
  );
};

export default ScriptsPage;
